import {
  Button,
  Checkbox,
  IconButton,
  lighten,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import KeyboardArrowUpOutlined from '@mui/icons-material/KeyboardArrowUpOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { CSSProperties, Fragment, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCommonStyles } from '../../styles/common-styles';
import { usePaginateHandler } from '../../utils/paginate-utils';
import PruTableEmptyRow from '../Table/PruTableEmptyRow';
import { PruTableHeader } from '../Table/PruTableHeader';
import PruTableLoading from '../Table/PruTableLoading';
import PruTablePaginationActions from '../Table/PruTablePaginationActions';
import StickyTableCell from './StickyTableCell';
import { PruTableRow } from '../Table/PruTableRow';

export enum PruTableSortTypeEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export type PruTableButtonDef = {
  color: 'inherit' | 'primary' | 'secondary';
  title: string | JSX.Element;
  onClick: () => void;
  condition?: () => boolean;
  disabled?: boolean;
  className?: string;
};

export type PruTableOperationDef<T> = {
  title: string | JSX.Element;
  tooltipText: string;
  condition?: (row: T, parent?: T) => boolean;
  onClick: (row: T, rowIndex: number) => void;
  style?: CSSProperties;
};

export type PruTableBulkSelectDef<T> = {
  title: string | JSX.Element;
  variant?: 'text' | 'outlined' | 'contained';
  color: 'inherit' | 'primary' | 'secondary';
  style?: any;
  onClick: (rows: T[], onResetRowSelected?: () => void) => void;
  condition?: (rows: T[]) => boolean;
};

export type PruTableColumnDef<T> = {
  isId?: boolean;
  hidden?: boolean;
  keyIndex: keyof T | string;
  childKeyIndex?: keyof T | string;
  displayName: string | JSX.Element;
  sortable?: boolean;
  replaceSortState?: boolean;
  style?: CSSProperties;
  renderData: (row: T, index: number, parent?: T) => string | JSX.Element;
  onSort?: (sort: SortState) => void;
} & TableCellProps;

type PruTableProps<T extends Record<string, any>> = {
  title?: string;
  subTitle?: string;
  containerClassName?: string;
  childRowClassName?: string;
  isLoading: boolean;
  disableBulkSelect?: boolean;
  singleSelect?: boolean;
  indeterminateSelectAll?: boolean;
  bulkSelectCheckboxDisable?: (rowData: any) => boolean;
  bulkSelectDef?: PruTableBulkSelectDef<T>[];
  currentSelectedRow?: (rowData: any, parent?: T, onResetRowSelected?: () => void) => void;
  disableRefresh?: boolean;
  disablePagination?: boolean;
  headerBtnDef?: PruTableButtonDef[];
  operationDef: PruTableOperationDef<T>[];
  columnDef: PruTableColumnDef<T>[];
  columnHeaderStyle?: CSSProperties;
  dataSource?: T[];
  totalRecords?: number;
  totalPages?: number;
  renderChildren?: boolean;
  autoSelectParent?: boolean;
  defaultRowsPerPage?: number;
  defaultPageNumber?: number;
  defaultOpenedRows?: string[];
  operationSticky?: boolean;
  hideBulkSelectHeader?: boolean;
  updateSelectedRow?: any[];
  noRecordMsg?: string;
  onRefresh?: () => void;
  onChangePage?: (page: number, rowsPerPage: number) => void;
  hideListTitleRow?: boolean;
  rowOnClicked?: (rowData: any) => void;
  type?: string;
  customEmptyTitle?: JSX.Element;
};

const useStyles = makeStyles<void, 'checked'>()((theme, _params, classes) => ({
  table: {
    minWidth: 700,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    width: '100%',
    padding: '20px 0 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bulkActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sortIcon: {
    color: `${theme.palette.common.white} !important`,
  },
  headerIcon: {
    // color: `${theme.palette.common.white} !important`,
    color: `#666 !important`,
    opacity: 1,
  },
  selectedRow: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  footer: {
    width: '100%',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#BBBBBB',
    cursor: 'default',
  },
  pagination: {
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  checked: {},
  checkboxItem: {
    width: '100%',
    borderRadius: '12px',
    [`&.${classes.checked}`]: {
      color: '#E8192C',
    },
  },
}));

type SortState = {
  [id: string]: PruTableSortTypeEnum | undefined;
};

const PruTable = <T extends Record<string, any>>({
  title,
  subTitle,
  containerClassName,
  childRowClassName,
  isLoading,
  disableRefresh,
  disableBulkSelect,
  disablePagination,
  singleSelect,
  indeterminateSelectAll = true,
  bulkSelectCheckboxDisable,
  currentSelectedRow,
  headerBtnDef,
  bulkSelectDef,
  operationDef,
  columnDef,
  columnHeaderStyle,
  dataSource,
  totalRecords,
  totalPages,
  renderChildren,
  autoSelectParent,
  operationSticky,
  defaultRowsPerPage,
  defaultPageNumber,
  defaultOpenedRows,
  hideBulkSelectHeader,
  updateSelectedRow,
  noRecordMsg,
  onRefresh,
  onChangePage,
  hideListTitleRow,
  rowOnClicked,
  type,
  customEmptyTitle,
}: PruTableProps<T>) => {
  const { classes, cx } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string | number>) => intl.formatMessage({ id }, variable);

  const colCount = useMemo(() => {
    return (
      columnDef.filter((column) => !column.hidden).length +
      Number(operationDef.length > 0) +
      Number(!!!disableBulkSelect)
    );
  }, [columnDef, operationDef, disableBulkSelect]);

  const { idKeyIndex, childKeyIndex } = useMemo(() => {
    const idCol = columnDef.find((column) => column.isId === true);
    return idCol ? { idKeyIndex: idCol.keyIndex, childKeyIndex: idCol.childKeyIndex } : { idKeyIndex: '' };
  }, [columnDef]);

  // Column Sort Handling
  const [sortState, setSortState] = useState<SortState>({});

  const toggleDirection = (direction: PruTableSortTypeEnum | undefined) => {
    if (direction === PruTableSortTypeEnum.ASC) {
      return PruTableSortTypeEnum.DESC;
    } else if (direction === PruTableSortTypeEnum.DESC) {
      return undefined;
    } else {
      return PruTableSortTypeEnum.ASC;
    }
  };

  const handleSort = (column: PruTableColumnDef<T>) => {
    if (column.sortable) {
      const newSortState: SortState = column.replaceSortState
        ? {
            [column.keyIndex]: toggleDirection(sortState[column.keyIndex as string]),
          }
        : {
            ...sortState,
            [column.keyIndex]: toggleDirection(sortState[column.keyIndex as string]),
          };
      setSortState(newSortState);
      if (column.onSort) {
        column.onSort(newSortState);
      }
    }
  };

  // Bulk Select Handling
  const [rowSelected, setRowSelected] = useState<T[]>([]);
  const [rowOpen, setRowOpen] = useState<string[]>(defaultOpenedRows ?? []);

  useEffect(() => {
    if (updateSelectedRow !== rowSelected) {
      setRowSelected(updateSelectedRow ?? []);
    }
  }, [updateSelectedRow]);

  const checkIsSelected = (row: T) =>
    !!rowSelected.find((selectedItem) => selectedItem[idKeyIndex] === row[idKeyIndex]);

  const checkHasChildren = (row: T, childKey?: keyof T) =>
    !!(childKey && Array.isArray(row[childKey]) && row[childKey].length > 0);

  const selectAllChecked = useMemo(() => {
    if (!disableBulkSelect && !isLoading && dataSource) {
      let data = [...dataSource];
      if (bulkSelectCheckboxDisable) {
        data = data.filter((row) => !bulkSelectCheckboxDisable(row));
      }
      return data.length > 0 && data.every((row) => checkIsSelected(row));
    }
    return false;
  }, [disableBulkSelect, isLoading, dataSource, bulkSelectCheckboxDisable, rowSelected]);

  const getChildRow = (row: T, childKey: keyof T) => {
    return row[childKey].reduce((result: T[], child: T) => {
      if (!checkIsSelected(child)) {
        result = [...result, child];
        if (checkHasChildren(child, childKey)) {
          result = [...result, ...getChildRow(child, childKey)];
        }
      }
      return result;
    }, []);
  };

  // get child id key list for unselecting
  const getUnselectChildIdKeyList = (row: T, childKey: keyof T) => {
    return row[childKey].reduce((result: string[], child: T) => {
      result = [...result, child[idKeyIndex]];
      if (checkHasChildren(child, childKey)) {
        result = [...result, ...getUnselectChildIdKeyList(child, childKey)];
      }
      return result;
    }, []);
  };

  const onResetRowSelected = () => {
    setRowSelected([]);
  };

  const onSelectAllRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newRowSelected: any[] = [];
    if (event.target.checked) {
      newRowSelected = dataSource?.filter((row) => !checkIsSelected(row)) || [];
      if (renderChildren) {
        let childRow: any[] = [];
        newRowSelected.forEach((row) => {
          if (childKeyIndex && checkHasChildren(row, childKeyIndex)) {
            childRow = [...childRow, ...getChildRow(row, childKeyIndex)];
          }
        });
        newRowSelected = [...newRowSelected, ...childRow];
      }
      if (bulkSelectCheckboxDisable) {
        newRowSelected = newRowSelected.filter((selectedItem) => !bulkSelectCheckboxDisable(selectedItem));
      }
      newRowSelected = [...rowSelected, ...newRowSelected];
    } else {
      newRowSelected = [...rowSelected];
      if (renderChildren) {
        dataSource?.forEach((row) => {
          if (childKeyIndex && checkHasChildren(row, childKeyIndex)) {
            const childIdKeyList = getUnselectChildIdKeyList(row, childKeyIndex);
            newRowSelected = newRowSelected.filter(
              (selectedItem) => !childIdKeyList.includes(selectedItem[idKeyIndex]),
            );
          }
        });
      }
      newRowSelected = newRowSelected.filter(
        (selectedItem) => !!!dataSource?.find((row) => row[idKeyIndex] === selectedItem[idKeyIndex]),
      );
    }
    setRowSelected(newRowSelected);
    if (currentSelectedRow) {
      currentSelectedRow(newRowSelected, undefined, onResetRowSelected);
    }
  };

  const onSelectRow = (row: T, hasChildren?: boolean, parent?: T) => {
    const foundIndex = rowSelected.findIndex((selectedItem) => selectedItem[idKeyIndex] === row[idKeyIndex]);
    let newRowSelected = singleSelect ? [] : [...rowSelected];
    if (foundIndex === -1) {
      newRowSelected.push(row);
      if (autoSelectParent && parent && !checkIsSelected(parent)) {
        newRowSelected.push(parent);
      }
      if (renderChildren && childKeyIndex && hasChildren) {
        newRowSelected = [...newRowSelected, ...getChildRow(row, childKeyIndex)];
      }
    } else {
      newRowSelected.splice(foundIndex, 1);
      if (renderChildren && childKeyIndex && hasChildren) {
        const childIdKeyList = getUnselectChildIdKeyList(row, childKeyIndex);
        newRowSelected = newRowSelected.filter((selectedItem) => !childIdKeyList.includes(selectedItem[idKeyIndex]));
      }
    }
    setRowSelected(newRowSelected);
    if (currentSelectedRow) {
      currentSelectedRow(newRowSelected, parent, onResetRowSelected);
    }
  };

  const onOpenRow = (row: T) => {
    const foundIndex = rowOpen.indexOf(row[idKeyIndex]);
    if (foundIndex === -1) {
      setRowOpen([...rowOpen, row[idKeyIndex]]);
    } else {
      const newArr = [...rowOpen];
      newArr.splice(foundIndex, 1);
      setRowOpen(newArr);
    }
  };

  const _renderOperationRow = (row: any, rowIndex: number, parent?: any) => {
    return (
      <div className={classes.operationContainer}>
        {operationDef.map((operation, index) => (
          <Fragment key={`operation-${index}`}>
            {((operation.condition !== undefined && operation.condition(row, parent)) || !operation.condition) && (
              <Tooltip title={operation.tooltipText}>
                <div
                  style={{ marginRight: operationDef[index + 1] ? 10 : 0 }}
                  className={`${classes.operationBtn}`}
                  onClick={() => operation.onClick(row, rowIndex)}
                >
                  {operation.title}
                </div>
              </Tooltip>
            )}
          </Fragment>
        ))}
      </div>
    );
  };

  const _renderTableRow = (row: any, index: number, level = 0, parent?: T) => {
    const isRowSelected = checkIsSelected(row);
    const isRowOpened = rowOpen.includes(row[idKeyIndex]);
    const hasChildren = checkHasChildren(row, childKeyIndex);
    const customChildRowClassName = parent && childRowClassName ? childRowClassName : undefined;
    return (
      <Fragment key={`row-${index}-level-${level}`}>
        <PruTableRow
          className={isRowSelected ? cx(classes.selectedRow, customChildRowClassName) : cx(customChildRowClassName)}
          onClick={() => {
            if (rowOnClicked) {
              rowOnClicked(row);
            }
          }}
        >
          {!disableBulkSelect && (
            <TableCell padding="checkbox">
              <Checkbox
                onClick={() => onSelectRow(row, hasChildren, parent)}
                checked={isRowSelected}
                disabled={bulkSelectCheckboxDisable ? bulkSelectCheckboxDisable(row) : false}
                classes={{
                  root: classes.checkboxItem,
                  checked: classes.checked,
                }}
              />
            </TableCell>
          )}

          {columnDef.map((column, columnIndex) => {
            return (
              !column.hidden && (
                <TableCell
                  style={column.style}
                  key={`table-row-field-${column.keyIndex as string}`}
                  align={column.align}
                >
                  {renderChildren && (
                    <Fragment>
                      {columnIndex === 0 && hasChildren && (
                        <span style={{ marginLeft: 18 * (level - 1) }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              onOpenRow(row);
                            }}
                          >
                            {!isRowOpened ? <AddBoxOutlinedIcon /> : <IndeterminateCheckBoxOutlinedIcon />}
                          </IconButton>
                        </span>
                      )}
                      {columnIndex === 0 && !hasChildren && <span style={{ marginLeft: 18 * level }}></span>}
                    </Fragment>
                  )}
                  <span style={{ fontFamily: 'Poppins, Helvetica, "sans-serif"' }}>
                    {column.renderData(row, index, parent)}
                  </span>
                </TableCell>
              )
            );
          })}
          {operationDef.length > 0 && !operationSticky && (
            <TableCell align="center">{_renderOperationRow(row, index, parent)}</TableCell>
          )}

          {operationDef.length > 0 && operationSticky && (
            <StickyTableCell align="center">{_renderOperationRow(row, index, parent)}</StickyTableCell>
          )}
        </PruTableRow>
        {renderChildren &&
          childKeyIndex &&
          isRowOpened &&
          hasChildren &&
          row[childKeyIndex].map((child: any, childIndex: number) =>
            _renderTableRow(child, childIndex, level + 1, row),
          )}
      </Fragment>
    );
  };

  // Paginate Handling
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePaginateHandler(
    onChangePage,
    totalPages,
    defaultPageNumber ? defaultPageNumber : 0,
    defaultRowsPerPage ? defaultRowsPerPage : undefined,
  );

  return (
    <div className={containerClassName}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            {!hideListTitleRow && (
              <>
                <TableRow>
                  <TableCell colSpan={Number.isInteger(colCount / 2) ? colCount / 2 : colCount / 2 + 0.5}>
                    {title && (
                      <div className={commonClasses.header}>
                        {title}
                        <span
                          style={{
                            color: '#888888',
                            fontSize: '12px',
                            marginLeft: '5px',
                          }}
                        >
                          {subTitle}
                        </span>
                      </div>
                    )}
                  </TableCell>
                  <TableCell colSpan={Number.isInteger(colCount / 2) ? colCount / 2 : colCount / 2 - 0.5} align="right">
                    <div style={{ justifyContent: 'flex-end' }} className={classes.rowContainer}>
                      {!disableRefresh && (
                        <Tooltip title="Refresh">
                          <IconButton onClick={onRefresh}>
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {headerBtnDef &&
                        headerBtnDef.map(
                          (btn) =>
                            (!btn.condition || (btn.condition !== undefined && btn.condition())) && (
                              <Button
                                key={`header-button-${btn.title}`}
                                style={{ marginLeft: 15 }}
                                variant="contained"
                                color="secondary"
                                onClick={btn.onClick}
                              >
                                {btn.title}
                              </Button>
                            ),
                        )}
                    </div>
                  </TableCell>
                </TableRow>
              </>
            )}

            {!hideBulkSelectHeader && !disableBulkSelect && rowSelected.length > 0 && (
              <TableRow>
                <TableCell padding="none" colSpan={colCount}>
                  <Toolbar className={classes.bulkActions}>
                    <Typography
                      color="inherit"
                      variant="subtitle1"
                      component="div"
                      style={{ fontFamily: 'Poppins, Helvetica, "sans-serif"' }}
                    >
                      {Translation('prutable.rowSelected', { num: rowSelected.length })}
                    </Typography>
                    <div>
                      {bulkSelectDef &&
                        bulkSelectDef.length > 0 &&
                        bulkSelectDef.map((action, bulkSelectDefIndex) => (
                          <Button
                            key={`bulk-select-${bulkSelectDefIndex}`}
                            variant={action.variant}
                            disabled={
                              !((action.condition !== undefined && action.condition(rowSelected)) || !action.condition)
                            }
                            color={action.color}
                            style={action.style}
                            onClick={async () => {
                              await action.onClick(rowSelected, onResetRowSelected);
                            }}
                          >
                            {action.title}
                          </Button>
                        ))}
                    </div>
                  </Toolbar>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              {!disableBulkSelect && (
                <PruTableHeader padding="checkbox" style={columnHeaderStyle}>
                  {!singleSelect && (
                    <Checkbox
                      disabled={Number(dataSource?.length) <= 0}
                      icon={<CheckBoxOutlineBlankOutlinedIcon style={{ color: columnHeaderStyle?.color }} />}
                      checkedIcon={
                        indeterminateSelectAll ? (
                          <IndeterminateCheckBoxIcon style={{ color: columnHeaderStyle?.color }} />
                        ) : undefined
                      }
                      checked={selectAllChecked}
                      onChange={onSelectAllRow}
                      inputProps={{ 'aria-label': 'select all rows' }}
                      classes={{
                        root: classes.checkboxItem,
                        checked: classes.checked,
                      }}
                    />
                  )}
                </PruTableHeader>
              )}
              {columnDef.map(
                (column) =>
                  !column.hidden && (
                    <Fragment key={`table-column-${column.keyIndex as string}`}>
                      <PruTableHeader
                        align={column.align}
                        style={
                          column.sortable
                            ? { cursor: 'pointer', whiteSpace: 'nowrap', ...columnHeaderStyle }
                            : columnHeaderStyle
                        }
                        onClick={() => handleSort(column)}
                      >
                        {column.displayName}
                        {column.sortable && sortState[column.keyIndex as string] !== undefined && (
                          <TableSortLabel
                            classes={{
                              icon: classes.headerIcon,
                            }}
                            className={classes.sortIcon}
                            active={true}
                            direction={sortState[column.keyIndex as string]}
                            IconComponent={KeyboardArrowUpOutlined}
                          />
                        )}
                      </PruTableHeader>
                    </Fragment>
                  ),
              )}
              {operationDef.length > 0 && !operationSticky && (
                <PruTableHeader align="center" style={columnHeaderStyle}>
                  {Translation('section.common.operation')}
                </PruTableHeader>
              )}
              {operationDef.length > 0 && operationSticky && (
                <StickyTableCell align="center">{Translation('section.common.operation')}</StickyTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <PruTableLoading isLoading={isLoading} />
            <PruTableEmptyRow
              isEmpty={!!(!dataSource || (dataSource && dataSource.length <= 0))}
              type={type}
              emptyText={noRecordMsg}
              customEmptyTitle={customEmptyTitle}
              colCount={colCount}
            />
            {dataSource && dataSource.map((row, index) => _renderTableRow(row, index))}
          </TableBody>
          {!disablePagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  colSpan={colCount}
                  count={totalRecords ?? 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    },
                  }}
                  classes={{ root: classes.pagination, selectRoot: classes.pagination }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={PruTablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default PruTable;
