import React, { FC, PropsWithChildren, createContext, useMemo } from 'react';
import { ModuleHierachy } from './types';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import { surveySubmitModuleConfig } from '../../modules/Survey/module-def';
import { landingModuleConfig } from '../../modules/Landing/module-def';
// import { agencyCampaignModuleConfig } from '../../modules/AgencyCampaign/module-def';
import { LitmosModuleConfig } from '../../modules/Litmos/module-def';
import { reviewWebFormModuleConfig } from 'src/app/modules/ReviewWebForm/module-def';
import { GlobalHelper } from '../helpers/GlobalHelpers';
import { SummaryDashboardModuleConfig } from 'src/app/modules/SummaryDashboard/module-def';
import { agentProfileModuleConfig } from 'src/app/modules/AgentProfile/module-def';
import { resourceModuleConfig } from 'src/app/modules/ResourceLibrary/module-def';
import { reportModuleConfig } from 'src/app/modules/Report/module-def';
import { managerDashboardModuleConfig } from 'src/app/modules/ManagerDashboard/module-def';
import { useJWT } from '../utils/hook-utils';
import { prospectModuleConfig } from 'src/app/modules/Prospect/module-def';
import { campaignModuleConfig } from 'src/app/modules/Campaign/module-def';
import { leadsModuleConfig } from 'src/app/modules/Leads/module-def';
import { insightModuleConfig } from 'src/app/modules/Insight/module-def';
import { eventModuleConfig } from 'src/app/modules/event-v2/module-def';
import { aiTalkbotModuleConfig } from 'src/app/modules/ai-talkbot/module-def';

type ModuleContextInternal = {
  moduleMap: ModuleHierachy[];
};

export const ModuleContext = createContext<ModuleContextInternal>({
  moduleMap: [],
});

const ModuleProvider: FC<PropsWithChildren> = ({ children }) => {
  const intl = useIntl();
  GlobalHelper.setGlobalIntlRef(intl);
  const token = useJWT();
  const moduleMap: ModuleHierachy[] = useMemo(() => {
    if (!token) {
      return [];
    }
    const permissions = map(token.permissions, (_, key) => key);
    const isBDM = token.role === 'MANAGER';

    return isBDM
      ? [
          landingModuleConfig(permissions, intl),
          resourceModuleConfig(permissions, intl),
          reportModuleConfig(permissions, intl),
        ]
      : [
          //  Export module configurations here
          // leadsModuleConfig(permissions, intl),
          // prospectModuleConfig(permissions, intl),
          // campaignModuleConfig(permissions, intl),
          // insightModuleConfig(permissions, intl),
          // aiTalkbotModuleConfig(permissions, intl),
          landingModuleConfig(permissions, intl),
          surveySubmitModuleConfig(permissions, intl),
          // agencyCampaignModuleConfig(permissions, intl),
          // PCAAEB-46075: comment on 24.07 for surveyjs upgrade
          // reviewWebFormModuleConfig(permissions, intl),
          // SummaryDashboardModuleConfig(permissions, intl),
          // agentProfileModuleConfig(permissions, intl),
          // managerDashboardModuleConfig(permissions, intl),
          // eventModuleConfig(permissions, intl),
          // Litmos needs to be the last module
          // LitmosModuleConfig(permissions, intl),

          // eslint-disable-next-line
        ];
    // eslint-disable-next-line
  }, [token]);

  return <ModuleContext.Provider value={{ moduleMap }}>{children}</ModuleContext.Provider>;
};

export default ModuleProvider;
