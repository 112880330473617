import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import PruTable from '@/app/common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../components/Filter';
import { BopReportItem, GpReportPayload, PaginateListMore } from '../types';
import { useDataProvider } from 'src/app/common/utils/hook-utils';
import moment from 'moment';
import { useStyles } from './styles';
import { fetchBopReportDownloadRequest, fetchListBopReportRequest } from '../../../network/bopReportCrud';

const initialState: GpReportPayload = {
  eventName: '',
  eventStartDate: moment().subtract(6, 'months').toISOString(),
  eventEndDate: moment().toISOString(),
  page: 1,
  limit: 20,
};

const paramsInitiator = (initialParams?: GpReportPayload): GpReportPayload => {
  return initialParams ? initialParams : initialState;
};

const BopReport = () => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();

  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<GpReportPayload>(paramsInitiator());
  const [reportList, setReportList] = useState<PaginateListMore<BopReportItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateListMore<BopReportItem>>(
    async () => {
      try {
        const payload: GpReportPayload = {
          ...filterState,
          ...getReportDateRange(filterState),
          eventName: trimString(filterState.eventName),
        };
        return await fetchListBopReportRequest(payload, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const trimString = (name: string) => {
    let str = name.trim();
    let strReserved = reverseString(str).trim();
    return reverseString(strReserved);
  };

  const reverseString = (str: string) => {
    return str.split('').reverse().join('');
  };

  const getReportDateRange = (data: GpReportPayload) => {
    const { eventEndDate, eventStartDate } = data;
    if (eventEndDate && eventStartDate) {
      return {
        eventEndDate: moment(eventEndDate).endOf('D').toISOString(),
        eventStartDate: moment(eventStartDate).startOf('D').toISOString(),
      };
    }

    if (eventEndDate) {
      return {
        eventEndDate: moment(eventEndDate).endOf('D').toISOString(),
        eventStartDate: moment(eventEndDate).startOf('D').subtract(6, 'months').toISOString(),
      };
    }

    if (eventStartDate) {
      return {
        eventStartDate: moment(eventStartDate).startOf('D').toISOString(),
        eventEndDate: moment(eventStartDate).endOf('D').add(6, 'months').toISOString(),
      };
    }

    return {
      eventEndDate: moment().endOf('D').toISOString(),
      eventStartDate: moment().startOf('D').subtract(6, 'months').toISOString(),
    };
  };

  const fetchCsvtDownload = () => {
    try {
      fetchBopReportDownloadRequest(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.bop-report.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'eventName',
            initialValue: filterState.eventName,
            displayName: Translation('report.event.event-name'),
            style: { width: '100%' },
          },

          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'eventStartDate',
            fieldTo: 'eventEndDate',
            initialDateFrom: filterState.eventStartDate
              ? new Date(filterState.eventStartDate)
              : moment().subtract(6, 'months').toDate(),
            initialDateTo: filterState.eventEndDate ? new Date(filterState.eventEndDate) : moment().toDate(),
            defaultDateFrom: moment().subtract(6, 'months').toDate(),
            defaultDateTo: moment().toDate(),
            displayName: Translation('report.event.event-date'),
            style: { width: '100%' },
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            eventName: data.eventName,
            eventStartDate: data.eventStartDate,
            eventEndDate: data.eventEndDate,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.bop-report.bop-event')}
        headerBtnDef={[
          {
            color: 'inherit',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
            className: classes.btnBlue,
          },
        ]}
        operationDef={[]}
        columnHeaderStyle={{
          backgroundColor: 'black',
          color: 'white',
          fontWeight: 'bold',
        }}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => row._id,
          },
          {
            keyIndex: 'bdm',
            displayName: Translation('report.bop-report.bdm'),
            renderData: (row) => row.bdm || '-',
            width: 200,
          },
          {
            keyIndex: 'officeCode',
            displayName: Translation('report.bop-report.officeCode'),
            renderData: (row) => row.officeCode || '-',
            width: 120,
          },
          {
            keyIndex: 'branchCode',
            displayName: Translation('report.bop-report.branchCode'),
            renderData: (row) => row.branchCode || '-',
            width: 120,
          },
          {
            keyIndex: 'unitCode',
            displayName: Translation('report.bop-report.unitCode'),
            renderData: (row) => row.unitCode || '-',
            width: 120,
          },
          {
            keyIndex: 'eventName',
            displayName: Translation('report.bop-report.eventName'),
            renderData: (row) => row.eventName || '-',
            width: 200,
          },
          {
            keyIndex: 'fullName',
            displayName: Translation('report.bop-report.candidate-name'),
            renderData: (row) => row.fullName || '-',
            width: 200,
          },
          {
            keyIndex: 'phoneNumber',
            displayName: Translation('report.bop-report.phoneNumber'),
            renderData: (row) => row.phoneNumber || '-',
            width: 150,
          },
          {
            keyIndex: 'agentCode',
            displayName: Translation('report.bop-report.agentCode'),
            renderData: (row) => row.agentCode || '-',
            width: 120,
          },
          {
            keyIndex: 'agentName',
            displayName: Translation('report.bop-report.agentName'),
            renderData: (row) => row.agentName || '-',
            width: 200,
          },
          {
            keyIndex: 'leaderCode',
            displayName: Translation('report.bop-report.leaderCode'),
            renderData: (row) => row.leaderCode || '-',
            width: 120,
          },
          {
            keyIndex: 'leaderName',
            displayName: Translation('report.bop-report.leaderName'),
            renderData: (row) => row.leaderName || '-',
            width: 200,
          },
          {
            keyIndex: 'materialEventStartDate',
            displayName: Translation('report.bop-report.materialEventStartDate'),
            renderData: (row) =>
              row.materialEventStartDate ? moment(row.materialEventStartDate).format('DD/MM/YYYY HH:mm') : '-',
            width: 160,
          },
          {
            keyIndex: 'materialEventEndDate',
            displayName: Translation('report.bop-report.materialEventEndDate'),
            renderData: (row) =>
              row.materialEventStartDate ? moment(row.materialEventEndDate).format('DD/MM/YYYY HH:mm') : '-',
            width: 160,
          },
          {
            keyIndex: 'registeredDate',
            displayName: Translation('report.bop-report.registeredDate'),
            renderData: (row) => (row.registeredDate ? moment(row.registeredDate).format('DD/MM/YYYY HH:mm') : '-'),
            width: 160,
          },
          {
            keyIndex: 'checkInDate',
            displayName: Translation('report.bop-report.checkInDate'),
            renderData: (row) => (row.checkInDate ? moment(row.checkInDate).format('DD/MM/YYYY HH:mm') : '-'),
            width: 160,
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default BopReport;
