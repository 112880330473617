import axios from 'axios';

export const fileUpload = (url: string, file: File, setProgress: (progress: number) => void) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        const buffer = reader.result;
        axios
          .put(url, buffer, { headers: { 'x-ms-blob-type': 'BlockBlob', 'Content-Type': file.type } })
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      };
      reader.readAsArrayBuffer(file);
    } catch (err) {
      reject(err);
    }
  });
};