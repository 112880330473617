import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import PruTable from '@common/components/PruTable/PruTable';
import { LibraryPublishStatus } from '../../../../constants';
import { LibraryItem, LibraryPaginateList } from '../../../../types';
import { getDefaultDisplayDate, renderDisplayStatusField } from '@/app/modules/ResourceLibrary/utils/common-utils';
import { operationTxt, rowTxtStyle, useStyles } from './styles';
import moment from 'moment';
import { get } from 'lodash';

type LibraryListProps = {
  isLoading: boolean;
  libraryList?: LibraryPaginateList;
  enableUpdate: boolean;
  enableCreate: boolean;
  libraryTableTitle: string;
  moduleType: string;
  currentLanguage: string;
  enablePerformance: boolean;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
  onAddLibrary: (type?: string, selectedRow?: LibraryItem[]) => void;
  onEditLibrary: (rowData: LibraryItem, mode: string) => void;
  onPerformance: (rowData: LibraryItem) => void;
  onUnpublishLibraryClicked: (rowData: LibraryItem) => void;
  onDownloadQR: (rowData: LibraryItem) => void;
};

const LibraryList: FC<LibraryListProps> = ({
  isLoading,
  libraryList,
  enableUpdate,
  enableCreate,
  libraryTableTitle,
  moduleType,
  currentLanguage,
  enablePerformance,
  onRefresh,
  onChangePage,
  onSort,
  onAddLibrary,
  onEditLibrary,
  onPerformance,
  onUnpublishLibraryClicked,
  onDownloadQR,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [selectedRowsState, setSelectedRows] = useState<any[]>([]);
  const { classes } = useStyles();

  const getColumnDisplayList = (columnRefList: any): string[] => {
    let resultList: string[] = [];
    columnRefList.map((item: any) => {
      if (item && item.column) {
        return resultList.push(` ${item.column.name[currentLanguage]}`);
      }
      return null;
    });

    return resultList;
  };

  const onPreview = (rowData: LibraryItem) => {
    const templateUrl = get(rowData, 'i18nData.link');
    const url = templateUrl ? `${templateUrl}?id=${rowData.id}` : 'http://google.com.vn';
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <PruTable
      title={Translation(libraryTableTitle)}
      headerBtnDef={[
        {
          color: 'inherit',
          title: Translation('app.button.add'),
          onClick: () => {
            onAddLibrary();
          },
          condition: () => !!enableCreate,
          className: classes.btnBlue,
        },
      ]}
      operationDef={[
        {
          title: Translation('app.button.edit'),
          tooltipText: Translation('app.button.edit'),
          onClick: (row) => {
            onEditLibrary(row, 'edit');
          },
          condition: (row) => moment(new Date()).isBefore(moment(row.eventStartDate)),
          style: operationTxt,
        },
        {
          title: Translation('app.button.view'),
          tooltipText: Translation('app.button.view'),
          onClick: (row) => {
            onEditLibrary(row, 'view');
          },
          style: operationTxt,
        },
        {
          title: Translation('app.button.unpublish'),
          tooltipText: Translation('app.button.unpublish'),
          onClick: (row) => onUnpublishLibraryClicked(row),
          condition: (row) => row.status === LibraryPublishStatus.Publish && !!enableUpdate && !row.publishToSetOnly,
          style: operationTxt,
        },
        {
          title: Translation('app.button.downloadQR'),
          tooltipText: Translation('app.button.downloadQR'),
          onClick: (row) => onDownloadQR(row),
          condition: (row) => !!row.eventInvitation && row.status !== LibraryPublishStatus.Draft,
          style: operationTxt,
        },
        {
          title: Translation('app.button.preview'),
          tooltipText: Translation('app.button.preview'),
          onClick: (row) => onPreview(row),
          style: operationTxt,
        },
      ]}
      columnDef={[
        {
          isId: true,
          keyIndex: 'id',
          hidden: true,
          displayName: '',
          renderData: (row) => '',
        },
        {
          keyIndex: 'name',
          align: 'left',
          displayName: Translation('component.formLabel.name'),
          renderData: (row) => String(row.name ? row.name : ''),
          style: rowTxtStyle,
        },
        {
          keyIndex: 'status',
          align: 'left',
          displayName: Translation('component.formLabel.status'),
          renderData: (row) => (row ? Translation(renderDisplayStatusField(row)) : ''),
          style: rowTxtStyle,
        },
        // {
        //   keyIndex: 'moduleColumn',
        //   align: 'left',
        //   displayName: Translation('component.formLabel.published-to-module'),
        //   renderData: (row) => String(row.columnReferences ? getColumnDisplayList(row.columnReferences) : ''),
        //   style: rowTxtStyle,
        // },
        {
          keyIndex: 'createdAt',
          align: 'left',
          displayName: Translation('component.formLabel.created-time'),
          renderData: (row) => (row.createdAt ? getDefaultDisplayDate(new Date(row.createdAt || ''), 'datetime') : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'createdAt', value: sort['createdAt'] });
          },
          style: rowTxtStyle,
        },
        {
          keyIndex: 'updatedAt',
          align: 'left',
          displayName: Translation('component.formLabel.last-updated-time'),
          renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.updatedAt), 'datetime') : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'updatedAt', value: sort['updatedAt'] });
          },
          style: rowTxtStyle,
        },
        {
          keyIndex: 'updatedBy',
          align: 'left',
          displayName: Translation('component.formLabel.last-updated-by'),
          renderData: (row) => String(row.lastUpdatedBy ? row.lastUpdatedBy : '-'),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'updatedBy', value: sort['updatedBy.lastUpdatedBy'] });
          },
          style: rowTxtStyle,
        },
        {
          keyIndex: 'effectiveDate',
          align: 'left',
          displayName: Translation('component.formLabel.effective-date-range-start'),
          renderData: (row) =>
            row.effectiveDate ? getDefaultDisplayDate(new Date(row.effectiveDate), 'datetime') : '',
          style: rowTxtStyle,
        },
        {
          keyIndex: 'expiredDate',
          align: 'left',
          displayName: Translation('component.formLabel.effective-date-range-end'),
          renderData: (row) =>
            String(row.expiredDate ? getDefaultDisplayDate(new Date(row.expiredDate), 'datetime') : ''),
          style: rowTxtStyle,
        },
      ]}
      columnHeaderStyle={{
        backgroundColor: 'black',
        color: 'white',
        fontWeight: 'bold',
      }}
      operationSticky={true}
      defaultRowsPerPage={20}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={libraryList?.libraryList}
      totalPages={libraryList?.totalPages}
      totalRecords={libraryList?.totalRecords}
      onChangePage={onChangePage}
      currentSelectedRow={(data) => setSelectedRows(data)}
      disableBulkSelect
    />
  );
};

export default LibraryList;
