import { find } from 'lodash';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { GlobalHelper } from 'src/app/common/helpers/GlobalHelpers';
import { useLang } from 'src/app/i18n';
import { RegionLocale, I18nGenericData } from '../types/i18n-types';

type LocaleContent = {
  name: string;
  flag: string;
};

export const regionLocaleMap: Record<RegionLocale, LocaleContent> = {
  [RegionLocale.ENGLISH]: {
    name: 'navBar.lang.en',
    flag: toAbsoluteUrl('/media/svg/flags/226-united-states.svg'),
  },
  [RegionLocale.TRADITIONAL_CHINESE]: {
    name: 'navBar.lang.zh-Hant',
    flag: toAbsoluteUrl('/media/svg/flags/183-hong-kong.svg'),
  },
  [RegionLocale.SIMPLIFIED_CHINESE]: {
    name: 'navBar.lang.zh-Hans',
    flag: toAbsoluteUrl('/media/svg/flags/015-china.svg'),
  },
  [RegionLocale.BAHASA]: {
    name: 'navBar.lang.id',
    flag: toAbsoluteUrl('/media/svg/flags/004-indonesia.svg'),
  },
  [RegionLocale.SPANISH]: {
    name: 'navBar.lang.es',
    flag: toAbsoluteUrl('/media/svg/flags/128-spain.svg'),
  },
  [RegionLocale.JAPANESE]: {
    name: 'navBar.lang.ja',
    flag: toAbsoluteUrl('/media/svg/flags/063-japan.svg'),
  },
  [RegionLocale.GERMAN]: {
    name: 'navBar.lang.de',
    flag: toAbsoluteUrl('/media/svg/flags/162-germany.svg'),
  },
  [RegionLocale.FRENCH]: {
    name: 'navBar.lang.fr',
    flag: toAbsoluteUrl('/media/svg/flags/195-france.svg'),
  },
  [RegionLocale.VIETNAM]: {
    name: 'navBar.lang.vi',
    flag: toAbsoluteUrl('/media/svg/flags/220-vietnam.svg'),
  },
};

const getRegionLocale = (): RegionLocale[] => {
  const lang = window.envConfig['REGION_LANGUAGE'];
  let arr = lang ? lang.split(',') : [RegionLocale.ENGLISH];
  // PVA: Fix extra character error
  arr = arr.map((item) => {
    if (item && item.includes('\r')) {
      item = item.replace('\r', '');
    }
    return item;
  });

  //  Only filter available languages
  return arr.filter((item) => find(RegionLocale, (locale) => locale === item)) as RegionLocale[];
};

export const regionLocale = getRegionLocale();

export const convertServerLocale = (locale: RegionLocale, sameAsAdminPortal?: boolean): string => {
  if (locale === RegionLocale.ENGLISH) {
    return sameAsAdminPortal ? 'enUs' : 'en-US';
  } else if (locale === RegionLocale.TRADITIONAL_CHINESE) {
    return sameAsAdminPortal ? 'zhHk' : 'zh-HK';
  } else {
    return locale;
  }
};

export const useServerLocale = (sameAsAdminPortal?: boolean) => {
  const lang = useLang();
  return convertServerLocale(lang, sameAsAdminPortal);
};

export const getAvailableServerLocale = () => {
  return regionLocale.map((locale) => convertServerLocale(locale));
};

export const Translation = (key: string) => {
  const intl = GlobalHelper.getGlobalIntlRef();
  return intl.formatMessage({ id: key });
};

export const TranslationWithNum = (key: string, count: number) => {
  const intl = GlobalHelper.getGlobalIntlRef();
  return intl.formatMessage({ id: key }, { num: count });
};

export const TranslationWithParams = (key: string, params: any) => {
  const intl = GlobalHelper.getGlobalIntlRef();
  return intl.formatMessage({ id: key }, params);
};

export const initI18nGenericData = <T>(regionLocale: RegionLocale[], defaultValue?: T): I18nGenericData<T> => {
  return regionLocale.reduce((result, locale) => ({ ...result, [locale]: defaultValue }), {});
};

export const initI18nGenericDataWithFunction = <T>(
  regionLocale: RegionLocale[],
  getDefaultValue?: (locale: RegionLocale) => T,
): I18nGenericData<T> => {
  return regionLocale.reduce(
    (result, locale) => ({ ...result, [locale]: getDefaultValue ? getDefaultValue(locale) : undefined }),
    {},
  );
};

export const backendLangCodeMapToFrontend = {
  en: 'en',
  id: 'id',
  my: 'my',
  'zh-Hant': 'zhHk',
  ph: 'fil_PH',
  km: 'km_KH',
  vi: 'vi'
};
