import React, { ReactNode, useState } from 'react';
import { Button, Dialog, DialogTitle } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';

interface ButtonDialogProps {
  btnClassName?: string;
  btnColor?: any;
  btnClasses?: string;
  btnTxt: string | ReactNode;
  dialogTxt: React.ReactNode;
  titleTxt?: string;
  btnVariant?: 'text' | 'outlined' | 'contained';
  onCancel?: () => void;
  onConfirm: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '20px',
  },
  btnContainer: {
    padding: '0 24px 24px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ButtonDialog = ({
  btnClassName,
  btnColor = 'inherit',
  btnTxt,
  btnVariant = 'outlined',
  dialogTxt,
  titleTxt,
  disabled,
  onCancel,
  onConfirm,
}: ButtonDialogProps) => {
  const [open, openModal] = useState<boolean>(false);
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
    openModal(false);
  };

  const handleConfirm = () => {
    onConfirm();
    openModal(false);
  };

  return (
    <>
      <Button
        disabled={disabled}
        className={btnClassName}
        variant={btnVariant}
        color={btnColor}
        onClick={() => openModal(true)}
      >
        {btnTxt}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        {titleTxt && (
          <DialogTitle style={{ padding: '24px 24px 0' }} sx={{ fontSize: 18, fontWeight: '600' }}>
            {titleTxt}
          </DialogTitle>
        )}
        <div className={classes.container}>{dialogTxt}</div>
        <div className={classes.btnContainer}>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            {Translation('app.button.cancel')}
          </Button>
          <Button variant="contained" color="secondary" onClick={handleConfirm}>
            {Translation('app.button.confirm')}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default ButtonDialog;
